import React from "react"
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessEmbed"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { getPostObj } from "../utils/utils"
import SEO from "../components/seo"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import HorizontalTwoColumns from "../components/Bulma/HorizontalTwoColumns"
import CTA from "../components/Bulma/CTA"
import { graphql } from "gatsby"

const SeHablaEspanolPage = ({ pageContext, location, data }) => {
  var post
  var language
  let postObj = getPostObj(pageContext, data.allUniquePagesJson.nodes[0])
  post = postObj.post
  language = pageContext.language

  return (
    <SharedStateProvider>
      <Layout layoutClass={"contact-fab"} language={language}>
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <div className="joshua-tree-content">
          <div className="columns top-section white-back">
            <div className="column is-4"></div>
            <div className="column">
              <h1
                className="mobile-left has-text-centered"
                style={{ marginTop: "0" }}
              >
                {post.heading}
              </h1>
            </div>
            <div className="column is-4"></div>
          </div>

          <HorizontalTwoColumns
            className="color-back is-vcentered"
            style={{
              padding: "40px 0"
            }}
            leftColWidth={2}
            middleColWidth={1}
            rightColWidth={2}
            leftColumn={
              <div className="column is-10">
                <NuvoImage
                  useAR
                  publicId={post.imageId}
                  cloudName="nuvolum"
                  width="auto"
                  responsive
                  responsiveUseBreakpoints="true"
                ></NuvoImage>
              </div>
            }
            rightColumn={
              <div className="column">
                <MarkdownViewer className="large-p" markdown={post.text} />
              </div>
            }
          />

          <CTA
            style={{
              padding: "40px 0"
            }}
            leftColWidth={5}
            rightColWidth={5}
            heading={post.getStarted.heading}
            text={post.getStarted.blurb}
            buttons={post.getStarted.buttons}
          />
          <div className="columns">
            <div className="column">
              <div
                className="columns body-section text-section is-mobile form-area"
                style={{ paddingTop: "0" }}
              >
                <div className="column is-1-mobile is-5-tablet"></div>
                <div className="column">
                  <div>
                    <h5>Empiece Aquí Formulario</h5>
                    <Seamless id="abb222d68bafe064118751f728654f1b" />
                  </div>
                </div>
                <div className="column is-1-mobile is-5-tablet"></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query SeHablaEspanolPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        text
        imageId
        getStarted {
          heading
          blurb
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
      }
    }
  }
`

export default SeHablaEspanolPage
