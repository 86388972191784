import PropTypes from "prop-types"
import React from "react"
import CenterColumn from "./CenterColumn"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer";
import ButtonGroupMap from "../Button/ButtonGroupMap"

var classNames = require("classnames")

export default function CTA(props) {
    let ctaClasses = classNames("bulma-cta", props.className);
    return (
            <CenterColumn
                className={ctaClasses}
                style={props.style}
                leftColWidth={props.leftColWidth}
                rightColWidth={props.rightColWidth}
                centerColumn={
                    <div className="column has-text-centered-tablet">
                        {props.heading && <h2>{props.heading}</h2>}
                        {props.text && <MarkdownViewer className="large-p" markdown={props.text} />}
                        {props.buttons && <ButtonGroupMap isCentered buttons={props.buttons} />}
                    </div>
                }
            />
    )
}

CTA.propTypes = {
    style: PropTypes.object,
    leftColWidth: PropTypes.number,
    rightColWidth: PropTypes.number,
    heading: PropTypes.string,
    text: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            button: PropTypes.shape({
                buttonText: PropTypes.string,
                href: PropTypes.string,
                youtube: PropTypes.string,
                appearance: PropTypes.string.isRequired,
                destination: PropTypes.string
              })
        })
    )
}
